import Vue from "vue";
import axios from "axios";

// initial state
const state = {
  // record form
  recordForm: {},
  editMode: false,
  showRecordForm: false,

  // paginate records
  totalPages: 0,
  records: [],
  count: 0,
  totalPrice: 0,
};

// mutations
const mutations = {
  SET_COUNT(state, count) {
    state.count = count;
  },

  SET_TOTAL_PRICE(state, totalPrice) {
    state.totalPrice = totalPrice;
  },

  // paginate records
  SET_TOTAL_PAGES(state, totalPages) {
    state.totalPages = totalPages;
  },

  SET_FILTERS(state, filters) {
    for (const key in filters) {
      if (filters[key] == "") {
        filters[key] = undefined;
      }
    }
    state.filters = filters;
  },

  SET_RECORDS(state, records) {
    state.records = records;
  },

  // record form
  SET_RECORD_FORM(state, recordForm) {
    state.recordForm = recordForm;
  },

  SET_EDIT_MODE(state, mode) {
    state.editMode = mode;
  },

  SHOW_RECORD_FORM(state) {
    state.showRecordForm = true;
  },

  HIDE_RECORD_FORM(state) {
    state.showRecordForm = false;
  },

  // manage
  UPDATE_RECORD(state, record) {
    let recordIndex = state.records.findIndex((item) => {
      return item.id == record.id;
    });
    Vue.set(state.records, recordIndex, JSON.parse(JSON.stringify(record)));
  },

  DELETE_RECORD(state, recordId) {
    state.records.forEach((item, index) => {
      if (item.id == recordId) state.records.splice(index, 1);
    });
  },
};

// actions
const actions = {
  fetchRecords({ state, commit }, params) {
    return axios
      .get("/dashboard/orders/list", { params: { ...params, ...state.filters } })
      .then((response) => {
        commit("SET_RECORDS", response.data.data);
        commit("SET_TOTAL_PAGES", response.data.last_page);
      });
  },

  fetchRecordsDetails({ state, commit }, { item }) {
    return axios
      .get(`/admin/order/${item}`, { params: state.filters })
      .then((response) => {
        commit("SET_COUNT", response.data[1]);
        commit("SET_TOTAL_PRICE", response.data[2]);
        commit("SET_RECORDS", response.data[0].data);
        commit("SET_TOTAL_PAGES", response.data.last_page);
      });
  },

  // =========================================================================
  // activate({}, { form }) {
  //   let payload = new FormData;
  //   for (const property in form) {
  //     if (!form.hasOwnProperty(property) || form[property] === null) {
  //       continue;
  //     }

  //     if (Array.isArray(form[property])) {
  //       for (const iterator of form[property]) {
  //         payload.append(property + '[]', iterator);
  //       }
  //     }
  //     else {
  //       payload.append(property, form[property]);
  //     }
  //   }
  //   // console.log(payload);
  //   return axios.post(`/admin/orders/change-status/${form.id}`, payload);
  // },

  //==========================================================================
  activate({}, record) {
    var bodyFormData = new FormData();
    // // console.log(record);
    // // console.log(record);
    bodyFormData.append("order_status_id", record.status_id);

    // // console.log(record.record);
    return axios.post(
      `/admin/orders/change-status/${record.record}`,
      bodyFormData
    );
  },
  // =========================================================================
  assign({}, { driversId, recordId }) {
    var bodyFormData = new FormData();
    // // console.log(record);
    // // console.log(record);
    bodyFormData.append("driver_id", driversId);

    // // console.log(record.record);
    return axios.post(`/admin/orders/assign/${recordId}`, bodyFormData);
  },
  // =========================================================================
  delete({ commit }, { recordId }) {
    return axios
      .delete(`/admin/category/${recordId}/delete`)
      .then((response) => {
        commit("DELETE_RECORD", recordId);
        return response;
      });
  },
};

export default {
  namespaced: true,
  name: "orders",
  state,
  actions,
  mutations,
};
