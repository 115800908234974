import Vue from 'vue';
import axios from 'axios';

const state = {
    social:  {},
};

const getters = {
    singleSocila: state => state.social,
};

const actions = {
   async fetchContact( { commit } ){
    const response = await axios.get(`/dashboard/settings/contact?showNames`);
    // console.log(response);
    commit("setSocial", response.data[0].content_en)
   },
   // the update contact
  async updateContact({ commit }, { singleSocila }) {
    let payload = new FormData();
    for (const property in singleSocila) {
      if (singleSocila.hasOwnProperty(property) && singleSocila[property] != null)
        payload.append(property, singleSocila[property]);
    }

    return await axios.post("/dashboard/settings/contact", payload).then((response) => {
      commit("setUpdateContact", response.data.data);
    });
  },
   
};

const mutations = {
    setSocial: (state, social) => (
        state.social = social
    ),
    setUpdateContact: (state, payload) => (
        state.editContactResponse = payload
    )
};

export default {
    namespaced: true,
    name: "social",
    state,
    getters,
    actions,
    mutations
}
