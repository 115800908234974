import Vue from "vue";
import axios from "axios";

// initial state
const state = {
  // record form
  recordForm: {},
  editMode: false,
  showRecordForm: false,

  id: 0,

  // paginate records
  filters: { page: 1 },
  total: 0,
  records: [],
};

// mutations
const mutations = {
  // paginate records
  SET_TOTAL_PAGES(state, total) {
    state.total = total;
  },

  SET_FILTERS(state, filters) {
    for (const key in filters) {
      if (filters[key] == "") {
        filters[key] = undefined;
      }
    }
    state.filters = filters;
  },

  SET_RECORDS(state, records) {
    state.records = records;
  },

  // record form
  SET_RECORD_FORM(state, recordForm) {
    state.recordForm = recordForm;
  },

  SET_EDIT_MODE(state, mode) {
    state.editMode = mode;
  },

  SHOW_RECORD_FORM(state) {
    state.showRecordForm = true;
  },

  HIDE_RECORD_FORM(state) {
    state.showRecordForm = false;
  },

};

// actions
const actions = {
  fetchRecords({ state, commit }) {
    return axios
      .get("/dashboard/users/list/chefs", { params: state.filters })
      .then((response) => {
        commit("SET_RECORDS", response.data.data);
        commit("SET_TOTAL_PAGES", response.data.total);
      });
  },
  

  block({ commit }, { record }) {
    var bodyFormData = new FormData();
    bodyFormData.append("user_id", record.id);
    bodyFormData.append("is_active", 0);
    return axios.put(`/dashboard/users/user_status`, bodyFormData);
  },

  unblock({ commit }, { record }) {
    var bodyFormData = new FormData();
    bodyFormData.append("user_id", record.id);
    bodyFormData.append("is_active", 1);

    return axios.put(`/dashboard/users/user_status`, bodyFormData);
  },
  // ==========================================

  markAsSubscription({commit},  record ) {
    var bodyFormData = new FormData();
    
    // // console.log(record);
    bodyFormData.append("user_id", record);
    // // console.log(bodyFormData);
    return axios.put(`/dashboard/users/renew`, bodyFormData);
  }
};

export default {
  namespaced: true,
  name: "chefs",
  state,
  actions,
  mutations,
};
