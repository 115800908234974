import Vue from "vue";
import axios from "axios";
import swal from "sweetalert";

// initial state
const state = {
  records: [],
  profile: {},

  formRecord: {},
  editMode: false,
  showForm: false,
  total_subcategories: 0,

  page: 1,
  totalPages: 0,
  filters: { page: 1 },
};
// getters
const getters = {
  adminInfo: (state) => state.profile,
}; 
// mutations
const mutations = {
  SET_TOTAL_SUBCATEGORIES(state, total) {
    state.total_subcategories = total;
  },
  SET_FILTERS(state, filters) {
    for (const key in filters) {
      if (filters[key] == "") {
        filters[key] = undefined;
      }
    }
    state.filters = filters;
  },
  SET_RECORDS(state, records) {
    state.records = records;
  },
  MY_PROFILE(state, profile) {
    state.profile = profile;
  },
  SET_TOTAL_PAGES(state, totalPages) {
    state.totalPages = Math.ceil(totalPages / 10);
    // // console.log(state.totalPages);
  },

  SET_PAGE(state, page) {
    state.page = page;
  },
   
  SET_FORM_RECORD(state, formRecord) {
    state.formRecord = formRecord;
  },

  SET_EDIT_MODE(state, mode) {
    state.editMode = mode;
  },

  SHOW_FORM(state) {
    state.showForm = true;
  },

  HIDE_FORM(state) {
    state.showForm = false;
  },

  APPEND_RECORD(state, record) {
    state.records.push(JSON.parse(JSON.stringify(record)));
  },

  UPDATE_RECORD(state, record) {
    let recordIndex = state.records.findIndex((item) => {
      return item.id == record.id;
    });
    Vue.set(state.records, recordIndex, JSON.parse(JSON.stringify(record)));
  },

  DELETE_RECORD(state, recordId) {
    state.records.forEach((item, index) => {
      if (item.id == recordId) state.records.splice(index, 1);
    });
  },

  MULTI_DELETE_RECORD(state, recordIds) {
    recordIds.forEach((recordId) => {
      const index = state.records.findIndex((item) => item.id === recordId);
      if (index !== -1) {
        state.records.splice(index, 1);
      }
    });
  },

};

// actions
const actions = {
  fetchRecords({ state, commit }) {
    let url ="/admin/subcategories/list";
    return axios
      .get(url, { params: {...state.filters} })
      .then((response) => {
        commit("SET_RECORDS", response.data.data);
        commit("SET_TOTAL_PAGES", response.data.total);
        commit("SET_TOTAL_SUBCATEGORIES", response.data.total);
      });
  },
  fetchMyProfile({ state, commit }) {
    return axios
      .get("/dashboard/account/myprofile")
      .then((response) => {
        // console.log(response.data);
        commit("MY_PROFILE", response.data);
      });
  },
  // =========================================================================

  searchRecords({ commit }, query) {
    return axios.get("/admin/admins/list?q=" + query).then((response) => {
      commit("SET_RECORDS_SEARCH", response.data);
      commit("SET_TOTAL_PAGES", response.data.last_page);
    });
  },

  // =========================================================================

  create({ commit }, { form }) {
    let payload = new FormData();
    for (const property in form) {
      if (form.hasOwnProperty(property) && form[property] != null)
        payload.append(property, form[property]);
    }

    return axios.post("/admin/subcategories/create", payload).then((response) => {
      commit("APPEND_RECORD", response.data);
    });
  },
  //==========================================================================
  
  // =========================================================================

  update({ dispatch }, { form }) {
    let payload = new FormData();
    for (const property in form) {
      if (form.hasOwnProperty(property) && form[property] != null)
        payload.append(property, form[property]);
    }
    // // console.log(payload);
    return axios.post(`/admin/subcategories/update/${form.id}`, payload).then(() => {
      dispatch("fetchRecords", {});
    });
  },

  // =========================================================================

  block({ commit }, { record }) {
    var bodyFormData = new FormData();
    bodyFormData.append("subcategory_id", record.id);
    bodyFormData.append("is_active", 0);
    return axios.put(`/admin/subcategories/change_status`, bodyFormData);
  },

  unblock({ commit }, { record }) {
    var bodyFormData = new FormData();
    bodyFormData.append("subcategory_id", record.id);
    bodyFormData.append("is_active", 1);
    return axios.put(`/admin/subcategories/change_status`, bodyFormData);
  },

  deleteSubCategory({ commit }, id) {
    return axios.delete(`/admin/subcategories/${id}`).then((response) => {
      commit("DELETE_RECORD", id);
      swal("نجحت العملية", "تمت حذف التصنيف الفرعي", "success");
      return response;
    });
  },

  multiDeleteRecords({ commit }, ids) {
    return axios.delete(`/admin/subcategories/multi/${ids}`).then((response) => {
      commit("MULTI_DELETE_RECORD", ids);
      swal("نجحت العملية", "تمت حذف السجلات المحددة بنجاح", "success");
      return response;
    });
  },

};

export default {
  namespaced: true,
  name: "subcategories",
  state,
  getters,
  actions,
  mutations,
};
