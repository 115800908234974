import Vue from "vue";
import axios from "axios";
import swal from "sweetalert";

// initial state
const state = {
  records: [],
  profile: {},

  formRecord: {},
  editMode: false,
  showForm: false,

  showSendNotificationForm: false,

  total_ads: 0,

  page: 1,
  totalPages: 0,
  filters: { page: 1 },
  details:{}
};
// getters
const getters = {
  adminInfo: (state) => state.profile,
  getDetails: (state) => state.details,
}; 
// mutations
const mutations = {
  SET_TOTAL_ADS(state, total) {
    state.total_ads = total;
  },
  SET_FILTERS(state, filters) {
    for (const key in filters) {
      if (filters[key] == "") {
        filters[key] = undefined;
      }
    }
    state.filters = filters;
  },
  SET_RECORDS(state, records) {
    state.records = records;
  },
  MY_PROFILE(state, profile) {
    state.profile = profile;
  },
  SET_TOTAL_PAGES(state, totalPages) {
    state.totalPages = Math.ceil(totalPages / 10);
    // // console.log(state.totalPages);
  },

  SET_PAGE(state, page) {
    state.page = page;
  },
   
  SET_FORM_RECORD(state, formRecord) {
    state.formRecord = formRecord;
  },

  SET_EDIT_MODE(state, mode) {
    state.editMode = mode;
  },

  SHOW_FORM(state) {
    state.showForm = true;
  },

  HIDE_FORM(state) {
    state.showForm = false;
  },


  SHOW_SEND_NOTIFICATION_FORM(state) {
    state.showSendNotificationForm = true;
  },

  HIDE_SEND_NOTIFICATION_FORM(state) {
    state.showSendNotificationForm = false;
  },


  APPEND_RECORD(state, record) {
    state.records.push(JSON.parse(JSON.stringify(record)));
  },

  UPDATE_RECORD(state, record) {
    let recordIndex = state.records.findIndex((item) => {
      return item.id == record.id;
    });
    Vue.set(state.records, recordIndex, JSON.parse(JSON.stringify(record)));
  },

  DELETE_RECORD(state, recordId) {
    state.records.forEach((item, index) => {
      if (item.id == recordId) state.records.splice(index, 1);
    });
  },
  MULTI_DELETE_RECORD(state, recordIds) {
    recordIds.forEach((recordId) => {
      const index = state.records.findIndex((item) => item.id === recordId);
      if (index !== -1) {
        state.records.splice(index, 1);
      }
    });
  },

  SET_RECORDS_DETAILS(state,payload){
    state.details =  payload
  }
};

// actions
const actions = {
  fetchRecords({ state, commit } ) {
    let url ="/admin/ads/list";
      return axios.get(url, { params: {...state.filters} })
      .then((response) => {
        commit("SET_RECORDS", response.data.data);
        commit("SET_TOTAL_PAGES", response.data.total);
        commit("SET_TOTAL_ADS", response.data.total);
      })
  },

  blockAddWithUser({ state, commit }, ad_id) {
    //send ad_id in urlencoded form
    return axios.post(`/admin/users/block_ad`, { ad_id: ad_id })
  },

  fetchMyProfile({ state, commit }) {
    return axios
      .get("/dashboard/account/myprofile")
      .then((response) => {
        // console.log(response.data);
        commit("MY_PROFILE", response.data);
      });
  },

  // =========================================================================

  searchRecords({ commit }, query) {
    return axios.get("/admin/admins/list?q=" + query).then((response) => {
      commit("SET_RECORDS_SEARCH", response.data);
      commit("SET_TOTAL_PAGES", response.data.last_page);
    });
  },

  // =========================================================================

  userAdsDetails({commit},id){
    return axios.get(`/admin/ads/single/${id}`).then((response) => {
      commit("SET_RECORDS_DETAILS", response.data);
    });
  },
  // =========================================================================

  block({ commit }, { record }) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", record.id);
    bodyFormData.append("is_active", 0);
    return axios.put(`/admin/ads/change_status`, bodyFormData);
  },

  unblock({ commit }, { record }) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", record.id);
    bodyFormData.append("is_active", 1);
    return axios.put(`/admin/ads/change_status`, bodyFormData);
  },

  send_notification({ commit }, { form }) {
    let payload = new FormData();
    for (const property in form) {
      if (form.hasOwnProperty(property) && form[property] != null)
        payload.append(property, form[property]);
    }

    return axios.post("/admin/ads/send_notification", payload).then((response) => {
      commit("APPEND_RECORD", response.data);

    });
  },
  deleteUserAd({ commit }, id) {
    return axios.delete(`/admin/ads/${id}`).then((response) => {
      commit("DELETE_RECORD", id);
      swal("نجحت العملية", "تمت حذف الإعلان", "success");
      return response;
    });
  },
  multiDeleteUserAd({ commit }, ids) {
    return axios.delete(`/admin/ads/multi/${ids}`).then((response) => {
      commit("MULTI_DELETE_RECORD", ids);
      swal("نجحت العملية", "تمت حذف الإعلانات بنجاح", "success");
      return response;
    });
  },
};

export default {
  namespaced: true,
  name: "userAds",
  state,
  getters,
  actions,
  mutations,
};
