<template>
  <v-app>
    <router-view v-if="$auth.ready()" />
    <loading v-else />
  </v-app>
</template>

<script>
import Loading from './views/Loading';

export default {
  name: 'entry-point',

  components: {
    Loading,
  },
};
</script>

<style lang="scss">
@import './assets/style/style.scss';
</style>
