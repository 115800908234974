import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.css';

// router
import router from './router.js';

// store
import store from './store';

//WYSIWYG
import wysiwyg from "vue-wysiwyg";
import DatetimePicker from 'vuetify-datetime-picker'
window._ = require('lodash');
window.Fire= new Vue();
// bus object
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'

Vue.use(Chartkick.use(Chart))
Vue.prototype.$bus = new Vue();

// plugins
import './plugins/axios';
import './plugins/vueauth';
import './plugins/mixins';
import vuetify from './plugins/vuetify';

// instantiation
Vue.config.productionTip = false;
import EntryPoint from './EntryPoint.vue';

Vue.use(wysiwyg, {}); 
Vue.use(DatetimePicker)

// global components
Vue.component('back-button', require('./components/BackButton.vue').default);

new Vue({
  store,
  router,
  vuetify,
  wysiwyg,
  DatetimePicker,
  
  render: h => h(EntryPoint)
}).$mount('#app') 

