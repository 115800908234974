/* eslint-disable */
import axios from "axios";
import swal from "sweetalert";

const state = {
  filter: { page: 1 },
  last_page: 0,
  total_areas: 0,
  AreasList: [],
  Area: {},
  countries: [],
  cities: [],
  city:{},
};

const getters = {
  getLastPage: (state) => state.last_page,
  getFilter: (state) => state.filter,
  getTotal_areas: (state) => state.total_areas,
  getAreasList: (state) => state.AreasList,
  getArea: (state) => state.Area,
  getCountries: (state) => state.countries,
  getCities: (state) => state.cities,
};
const mutations = {
  setLastPage(state, payload) {
    state.last_page = payload;
  },
  setFilter(state, payload) {
    state.filter = payload;
  },
  setTotal_areas(state, payload) {
    state.total_areas = payload;
  },
  setAreasList(state, payload) {
    state.AreasList = payload;
  },
  setArea(state, payload) {
    state.Area = payload;
  },

  MULTI_DELETE_RECORD(state, recordIds) {
    recordIds.forEach((recordId) => {
      const index = state.AreasList.findIndex((item) => item.id === recordId);
      if (index !== -1) {
        state.AreasList.splice(index, 1);
      }
    });
  },
  setCountries(state, payload) {
    state.countries = payload;
  },
  setCities(state, payload) {
    state.cities = payload;
  },
  setCity(state, payload){
    state.city = payload
  }
};
const actions = {
  // Area CRUD
  fetchAreas({ commit , state }) {
    return axios
      .get(
        `/admin/areas`,
        { params: { ...state.filter} }
      )
      .then((response) => {
        commit("setAreasList", response.data.data);
        commit("setLastPage", response.data.last_page);
        commit("setTotal_areas", response.data.total);
        return response;
      });
  },
  postArea({ commit }, item) {
    let formData = new FormData();
    formData.append("city_id", item.city_id);
    formData.append("name_en", item.name_en);
    formData.append("name_ar", item.name_ar);
    formData.append("order", item.order);
    formData.append("active", item.active ? 1 : 0);
    return axios
      .post(`/admin/areas`, formData)
      .then((response) => {
        commit("setArea", response.data);
        swal("نجحت العملية", "تمت اضافة المنطقة", "success");
        return response;
      })
      .catch((err) => {
        swal("خطأ", "البيانات غير صحيحة", "error");
      });
  },
  //postCity
  postCity({ commit }, item) {
    // console.log("stoore" , item)
    let formData = new FormData();
    formData.append("country_id", item.country_id);
    formData.append("name_en", item.name_en);
    formData.append("name_ar", item.name_ar);
    formData.append("order", item.order);
    formData.append("active", item.active ? 1 : 0);
    return axios
      .post(`/admin/cities`, formData)
      .then((response) => {
        commit("setCity", response.data);
        swal("نجحت العملية", "تمت اضافة المحافظة", "success");
        return response;
      })
      .catch((err) => {
        swal("خطأ", "البيانات غير صحيحة", "error");
      });
  },
  updateArea({ commit }, item) {
    let formData = new FormData();
    let id = item.id;
    item.city_id ? formData.append("city_id", item.city_id) : null;
    item.name_en ? formData.append("name_en", item.name_en) : null;
    item.name_ar ? formData.append("name_ar", item.name_ar) : null;
    item.order ? formData.append("order", item.order) : null;
    formData.append("active", item.active ? 1 : 0);
    return axios.put(`/admin/areas/${id}`, formData).then((response) => {
      commit("setArea", response.data.data);
      swal("AreaUpdatedSuccessfully", "تمت تعديل المنطقة", "success");
      return response;
    });
  },
  fetchCountries({ commit }) {
    return axios
      .get(`/structure/countries`)
      .then((response) => {
        commit("setCountries", response.data);
        return response;
      });
  },
  fetchCities({ commit }, Country) {
    return axios
      .get(`/structure/cities`, { headers: { Country: `${Country}` } })
      .then((response) => {
        commit("setCities", response.data);
        return response;
      });
  },
  deleteCity({ commit }, id) {
    return axios.delete(`/admin/cities/${id}`).then((response) => {
      // commit("setCities", response.data.data);
      swal("نجحت العملية", "تمت حذف المنطقة", "success");
      return response;
    });
  },
  deleteArea({ commit }, id) {
    return axios.delete(`/admin/areas/${id}`).then((response) => {
      commit("setArea", response.data.data);
      swal("نجحت العملية", "تمت حذف المنطقة", "success");
      return response;
    });
  },
  multiDeleteAreas({ commit }, ids) {

    return axios.delete(`/admin/areas/multi/${ids}`).then((response) => {
      commit("MULTI_DELETE_RECORD", ids);
      swal("نجحت العملية", "تمت حذف المناطق بنجاح", "success");
      return response;
    });
  },
};

export default {
  namespaced: true,
  name: "Areas",
  state,
  getters,
  actions,
  mutations,
};
