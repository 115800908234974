import Vue from 'vue';

import auth from '@websanova/vue-auth';
import authBasic from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js';
import httpVueResource from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import routerVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';

Vue.use(auth, {
  auth: authBasic,
  http: httpVueResource,
  router: routerVueRouter,
  tokenStore: ['storage'],
  tokenDefaultKey: 'token',

  fetchData: {
    url: '/admin/admins/list',
    method: 'GET',
    enabled: true
  },

  logoutData: {
    url: '/admin/account/logout',
  },

  refreshData: {
    enabled: false,
    interval: 0
  },

  parseUserData: function (data) {
    return data.data.user;
  }
});
