/* eslint-disable */
import axios from "axios";
import swal from "sweetalert";

const state = {
  filter: { page: 1 },
  last_page: 0,
  total_Models: 0,
  ModelsList: [],
  Model: {},
  Sub_category: [],
};

const getters = {
  getLastPage: (state) => state.last_page,
  getFilter: (state) => state.filter,
  getTotal_Models: (state) => state.total_Models,
  getModelsList: (state) => state.ModelsList,
  getModel: (state) => state.Model,
  getSub_category: (state) => state.Sub_category,
};
const mutations = {
  setLastPage(state, payload) {
    state.last_page = payload;
  },
  setFilter(state, payload) {
    state.filter = payload;
  },
  setTotal_Models(state, payload) {
    state.total_Models = payload;
  },
  setModelsList(state, payload) {
    state.ModelsList = payload;
    // console.log(state.ModelsList);
  },
  setModel(state, payload) {
    state.Model = payload;
  },
  setSub_category(state, payload) {
    state.Sub_category = payload;
  },

  MULTI_DELETE_RECORD(state, recordIds) {
    recordIds.forEach((recordId) => {
      const index = state.ModelsList.findIndex((item) => item.id === recordId);
      if (index !== -1) {
        state.ModelsList.splice(index, 1);
      }
    });
  },
};
const actions = {
  // Model CRUD
  fetchModels({ commit } ) { 
    return axios
      .get(`/admin/models`, { params: { ...state.filter} })
      .then((response) => {
        commit("setModelsList", response.data.data);
        commit("setLastPage", response.data.last_page);
        commit("setTotal_Models", response.data.total);
        return response;
      });
  },
  postModel({ commit }, item) {
    let formData = new FormData();
    formData.append("sub_category_id", item.sub_category_id);
    formData.append("name_en", item.name_en);
    formData.append("name_ar", item.name_ar);
    formData.append("order", item.order);
    formData.append("active", item.active ? 1 : 0);
    return axios
      .post(`/admin/models`, formData)
      .then((response) => {
        commit("setModel", response.data);
        swal("نجحت العملية", "تمت اضافة المنطقة", "success");
        return response;
      })
      .catch((err) => {
        swal("خطأ", "البيانات غير صحيحة", "error");
      });
  },
  updateModel({ commit }, item) {
    let formData = new FormData();
    let id = item.id;
    item.sub_category_id ? formData.append("sub_category_id", item.sub_category_id) : null;
    item.name_en ? formData.append("name_en", item.name_en) : null;
    item.name_ar ? formData.append("name_ar", item.name_ar) : null;
    item.order ? formData.append("order", item.order) : null;
     formData.append("active",  item.active ? 1 : 0);
    return axios.put(`/admin/models/${id}`, formData).then((response) => {
      commit("setModel", response.data.data);
      swal("ModelUpdatedSuccessfully", "تمت تعديل المنطقة", "success");
      return response;
    });
  },
  fetchSub_category({ commit }) {
    return axios.get(`/admin/models/sub-categories` , { headers: { Country : 'SA' } }).then((response) => {
        commit("setSub_category", response.data);
        return response;
        })
    },
  deleteModel({ commit }, id) {
    return axios.delete(`/admin/models/${id}`).then((response) => {
      commit("setModel", response.data.data);
      swal("نجحت العملية", "تمت حذف المنطقة", "success");
      return response;
    });
  },
  multiDeleteRecords({ commit }, ids) {

    return axios.delete(`/admin/models/multi/${ids}`).then((response) => {
      commit("MULTI_DELETE_RECORD", ids);
      swal("نجحت العملية", "تمت حذف السجلات المحددة بنجاح", "success");
      return response;
    });
  },

  block({ commit }, { record }) {
    var bodyFormData = new FormData();
    bodyFormData.append("model_id", record.id);
    bodyFormData.append("is_active", 0);

    return axios.post(`/admin/models/change_status`, bodyFormData);
  },

  unblock({ commit }, { record }) {
    var bodyFormData = new FormData();
    bodyFormData.append("model_id", record.id);
    bodyFormData.append("is_active", 1);

    return axios.post(`/admin/models/change_status`, bodyFormData);

  },
};

export default {
  namespaced: true,
  name: "Models",
  state,
  getters,
  actions,
  mutations,
};
