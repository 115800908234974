import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router(
	{
		mode: 'history',
		routes: [
			{
				path: '/',
				redirect: '/home',
				meta: { auth: true },
				component: () => import('./App.vue'),
				children: [
					{
						path: 'home',
						name: 'home',
						component: () => import('./views/home/Home.vue'),
					},
					{
						path: 'users',
						name: 'users',
						component: () => import('./views/users/Users.vue'),
					},
					{
						path: 'areas',
						name: 'areas',
						component: () => import('./views/areas/areas.vue'),
					},
					{
						path: 'models',
						name: 'models',
						component: () => import('./views/models/models.vue'),
					},
					{
						path: 'sliders',
						name: 'sliders',
						component: () => import('./views/Sliders/sliders.vue'),
					},
					{
						path: 'sections',
						name: 'sections',
						component: () => import('./views/sections/Sections.vue'),
					},
					{
						path: 'categories',
						name: 'categories',
						component: () => import('./views/categories/Categories.vue'),
					},
					{
						path: 'subcategories',
						name: 'subcategories',
						component: () => import('./views/subCategories/SubCategories.vue'),
					},
					{
						path: 'offers',
						name: 'offers',
						component: () => import('./views/offers/Offers.vue'),
					},
					{
						path: 'ads',
						name: 'ads',
						component: () => import('./views/ads/Ads.vue'),
					},
					{
						path: 'userAds',
						name: 'userAds',
						component: () => import('./views/userAds/userAds.vue'),
					},
					//userAds
					//introads
					{
						path: 'commercialAds',
						name: 'commercialAds',
						component: () => import('./views/commercialAds/commercialAds.vue'),
					},
					//banners
					{
						path: 'Banners',
						name: 'Banners',
						component: () => import('./views/banners/Banners.vue'),
					},
					//advertising_rates_list
					{
						path: 'AdvertisingRatesLists',
						name: 'AdvertisingRatesLists',
						component: () => import('./views/advertising_rates_list/AdvertisingRatesLists.vue'),
					},
					{
						path: 'trends',
						name: 'trends',
						component: () => import('./views/trends/Trends.vue'),
					},
					{
						path: 'orders',
						name: 'orders',
						component: () => import('./views/orders/Orders.vue'),
					},
					{
						path: 'orders/:id',
						name: 'orderDetails',
						component: () => import('./views/orders/Single.vue'),
					},

					{
						path: 'admins',
						name: 'admins',
						component: () => import('./views/admins/Admins.vue'),
					},
					{
						path: 'profile',
						name: 'profile',
						component: () => import('./views/admins/Profile.vue'),
					},
					{
						path: 'contact-us',
						name: 'contact-us',
						component: () => import('./views/contactUs/ContactUs.vue'),
					},
					{
						path: 'social',
						name: 'social',
						component: () => import('./views/Social/Social.vue'),
					},
					{
						path: 'edit-social',
						name: 'edit-social',
						component: () => import('./views/Social/Edit-Social.vue'),
					},
					{
						path: 'notifications',
						name: 'notifications',
						component: () => import('./views/notifications/Notifications.vue'),
					},
					{
						path: 'wallet_balances',
						name: 'wallet_balances',
						component: () => import('./views/wallet_balances/WalletBalances.vue'),
					},
					{
						path: 'wallet_transactions',
						name: 'wallet_transactions',
						component: () => import('./views/wallet_transactions/WalletTransactions.vue'),
					},
					{
						path: 'admin/pages/:page',
						name: 'settings',
						component: () => import('./views/settings/Settings.vue'),
					},
				],
			},
			{
				path: '/login',
				name: 'Login',
				meta: { auth: false },
				component: () => import('./views/Login.vue'),
			},
			{
				path: '/403',
				name: 'unauthorized',
				component: () => import('./views/Unauthorized.vue'),
			},
			{
				path: '*',
				name: 'Not Found',
				component: () => import('./views/NotFound.vue'),
			},
		],
	},

	// other routes
);

Vue.router = router;
export default router;
