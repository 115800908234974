import Vue from 'vue';
import axios from 'axios';

// initial state
const state = {
  // record form
  recordForm: {},
  editMode: false,
  showRecordForm: false,

  // paginate records
  filters: { page: 1 },
  totalPages: 0,
  records: [],
}

// mutations
const mutations = {

  // paginate records
  SET_TOTAL_PAGES(state, totalPages) {
    state.totalPages = totalPages;
  },

  SET_FILTERS(state, filters) {
    for (const key in filters) {
      if (filters[key] == '') {
        filters[key] = undefined;
      }
    }
    state.filters = filters;
  },

  SET_RECORDS(state, records) {
    state.records = records;
  },

  // record form
  SET_RECORD_FORM(state, recordForm) {
    state.recordForm = recordForm;
  },

  SET_EDIT_MODE(state, mode) {
    state.editMode = mode;
  },

  SHOW_RECORD_FORM(state) {
    state.showRecordForm = true;
  },

  HIDE_RECORD_FORM(state) {
    state.showRecordForm = false;
  },

  // manage
  UPDATE_RECORD(state, record) {
    let recordIndex = state.records.findIndex((item) => {
      return item.id == record.id;
    });
    Vue.set(state.records, recordIndex, JSON.parse(JSON.stringify(record)));
  },

  DELETE_RECORD(state, recordId) {
    state.records.forEach((item, index) => {
      if (item.id == recordId) state.records.splice(index, 1);
    });
  },
}

// actions
const actions = {

  fetchRecords({ state, commit }) {
    return axios.get('/admin/notification', { params: state.filters })
      .then((response) => {
        commit('SET_RECORDS', response.data.data);
        commit('SET_TOTAL_PAGES', response.data.last_page);
      });
  },

  // =========================================================================

  create({}, { form }) {
    let payload = new FormData;
    for (const property in form) {
      if (!form.hasOwnProperty(property) || form[property] === null) {
        continue;
      }

      if (Array.isArray(form[property])) {
        for (const iterator of form[property]) {
          payload.append(property + '[]', iterator);
        }
      }
      else {
        payload.append(property, form[property]);
      }
    }

    return axios.post('/admin/push-notifications/send', payload);
  },

  // =========================================================================

  update({}, { form }) {
    let payload = new FormData;
    for (const property in form) {
      if (!form.hasOwnProperty(property) || form[property] === null) {
        continue;
      }

      if (Array.isArray(form[property])) {
        for (const iterator of form[property]) {
          payload.append(property + '[]', iterator);
        }
      }
      else {
        payload.append(property, form[property]);
      }
    }

    return axios.post(`/admin/notification/${form.id}/update`, payload);
  },

  // =========================================================================

  delete({ commit }, { recordId }) {
    return axios.delete(`/admin/notification/${recordId}/delete`)
      .then((response) => {
        commit('DELETE_RECORD', recordId);
        return response;
      });
  }
}

export default {
  namespaced: true,
  name: 'notifications',
  state,
  actions,
  mutations
}
