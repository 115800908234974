
<template>

  <div id="app" class='swiper'>
    <circle8></circle8>
  </div>
</template>
<script>
  import {Circle8} from 'vue-loading-spinner'
  export default {
    components: {
      Circle8
    }
  }
</script>


<style scoped>
.swiper {
    position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transform: translateX(-50%);
}
</style>