import axios from "axios";

// initial state
const state = {
  sections: [],
  categories: [],
  countries: [],
  subCategories: [],
};

// mutations
const mutations = {};

// actions
const actions = {
  fetchSections({ state }) {
    axios.get("/structure/sections").then((response) => {
      for (const i of response.data) {
        state.sections.push({ value: i.id, text: i.name });
      }
    });
  },

  fetchCategories({ state }, sectionId) {
    state.categories.length = 0;
    state.categories=[];
    axios.get(`/structure/categories/${sectionId}`).then((response) => {
      for (const i of response.data) {
        state.categories.push({ value: i.id, text: i.name });
      }
    });
  },

  fetchCountries({ state }) {
    axios.get("/admin/sliders/countries_dropdown").then((response) => {
      state.countries = response.data;
    });
  },
  //sub category
  fetchSubCategories({state} , categoryId) {
   axios
      .get(`/structure/sub-categories/${categoryId}`)
      .then((response) => {
        for (const i of response.data) {
          state.subCategories.push({ value: i.id, text: i.name });
        }
      });
  },
};

export default {
  namespaced: true,
  name: "filters",
  state,
  actions,
  mutations,
};
