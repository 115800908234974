import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	rtl: true,
	theme: {
		themes: {
			light: {
				primary: '#41C3DB',
				secondary: '#41C3DB',
				accent: '#41C3DB',
				success: '#51a057',
				error: '#af4545',
			},
		},
	},
});
//#fb7925
//#4184ea
