import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
Vue.axios.defaults.headers.common['Accept-Language'] = 'ar';
Vue.axios.defaults.headers.common['x-platform'] = 'android';
Vue.axios.defaults.headers.common['x-version'] = '4.0.2';

Vue.axios.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        // console.log(error, error.response);

        if (error.response && error.response.status == 403) {
            Vue.prototype.$bus.$emit('showSnackbar', {
                text: 'There is no Permission',
                color: 'error'
            });
        };

        if (error.response && error.response.status == 429) {
            Vue.prototype.$bus.$emit('showSnackbar', {
                text: 'تكرار العمليات أكثر من 30 مرة في الدقيقة قد يجهد السيرفر .. يرحي الانتظار',
                color: 'warning'
            });
        };

        return Promise.reject(error);
    }
);