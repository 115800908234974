import Vue from "vue";
import axios from "axios";

// initial state
const state = {
  records: [],
  profile: {},

  formRecord: {},
  editMode: false,
  showForm: false,
  total_admins: 0,

  page: 1,
  totalPages: 0,
  filters: { page: 1 },
};
// getters
const getters = {
  adminInfo: (state) => state.profile,
  getFilter: (state) => state.filters,
}; 
// mutations
const mutations = {
  SET_TOTAL_ADMINS(state , total){
    state.total_admins = total
  },
  SET_FILTERS(state, filters) {
    for (const key in filters) {
      if (filters[key] == "") {
        filters[key] = undefined;
      }
    }
    state.filters = filters;
  },
  SET_RECORDS(state, records) {
    state.records = records;
  },
  MY_PROFILE(state, profile) {
    state.profile = profile;
  },
  SET_TOTAL_PAGES(state, totalPages) {
    state.totalPages = Math.ceil(totalPages / 10);
    // // console.log(state.totalPages);
  },
 
  SET_PAGE(state, page) {
    state.page = page;
  },
  EDIT_PROFILE(state, payload)
    {
        state.editProfileResponse = payload
    },
   
  SET_FORM_RECORD(state, formRecord) {
    state.formRecord = formRecord;
  },

  SET_EDIT_MODE(state, mode) {
    state.editMode = mode;
  },

  SHOW_FORM(state) {
    state.showForm = true;
  },

  HIDE_FORM(state) {
    state.showForm = false;
  },

  APPEND_RECORD(state, record) {
    state.records.push(JSON.parse(JSON.stringify(record)));
  },

  UPDATE_RECORD(state, record) {
    let recordIndex = state.records.findIndex((item) => {
      return item.id == record.id;
    });
    Vue.set(state.records, recordIndex, JSON.parse(JSON.stringify(record)));
  },

  DELETE_RECORD(state, recordId) {
    state.records.forEach((item, index) => {
      if (item.id == recordId) state.records.splice(index, 1);
    });
  },
};

// actions
const actions = {
  fetchRecords({ state, commit }) {
    let url =`/admin/admins/list`;
    return axios
      .get(url, { params: {...state.filters} })
      .then((response) => {
        commit("SET_RECORDS", response.data.data);
        commit("SET_TOTAL_PAGES", response.data.total);
        commit("SET_TOTAL_ADMINS", response.data.total)
      });
  },
  fetchMyProfile({ state, commit }) {
    return axios
      .get("/dashboard/account/myprofile")
      .then((response) => {
        // console.log(response.data);
        commit("MY_PROFILE", response.data);
      });
  },
  /*

    fetchRecords({ state, commit }) {
          return axios
              .get("/admin/users/all_users", { params: state.filters })
              .then((response) => {
                  commit("SET_RECORDS", response.data.data);
                  commit("SET_TOTAL_PAGES", response.data.last_page);
              });
      },


                        */
  // =========================================================================

  searchRecords({ commit }, query) {
    return axios.get("/admin/admins/list?q=" + query).then((response) => {
      commit("SET_RECORDS_SEARCH", response.data);
      commit("SET_TOTAL_PAGES", response.data.last_page);
    });
  },

  // =========================================================================

  create({ commit }, { form }) {
    let payload = new FormData();
    for (const property in form) {
      if (form.hasOwnProperty(property) && form[property] != null)
        payload.append(property, form[property]);
    }

    return axios.post("/admin/admins/create", payload).then((response) => {
      commit("APPEND_RECORD", response.data);
    });
  },
  //==========================================================================
  updateProfile({ commit }, { adminInfo }) {
    let payload = new FormData();
    // console.log(payload);
    for (const property in adminInfo) {
      if (adminInfo.hasOwnProperty(property) && adminInfo[property] != null)
        payload.append(property, adminInfo[property]);
    }

    return axios.post("/dashboard/account/update", payload).then((response) => {
      // console.log(response.data)
      commit("EDIT_PROFILE", response.data);
    });
  },
  
  // =========================================================================

  update({ dispatch }, { form }) {
    let payload = new FormData();
    for (const property in form) {
      if (form.hasOwnProperty(property) && form[property] != null)
        payload.append(property, form[property]);
    }
    // // console.log(payload);
    return axios.post(`/admin/admins/update/${form.id}`, payload).then(() => {
      dispatch("fetchRecords", {});
    });
  },

  // =========================================================================

  //   delete({ commit }, { recordId }) {
  //     return axios.delete(`/admin/${recordId}`).then((response) => {
  //       commit("DELETE_RECORD", recordId);
  //       return response;
  //     });
  //   },
  block({ commit }, { record }) {
    var bodyFormData = new FormData();
    bodyFormData.append("user_id", record.id);
    bodyFormData.append("is_active", 0);
    return axios.put(`/admin/users/change_status`, bodyFormData);
  },

  unblock({ commit }, { record }) {
    var bodyFormData = new FormData();
    bodyFormData.append("user_id", record.id);
    bodyFormData.append("is_active", 1);
    // // console.log(bodyFormData);
    // // console.log("id" + record.id);

    return axios.put(`/admin/users/change_status`, bodyFormData);

    //  return axios.post(`/admin/users/${record.id}/unblock`)
  },
};

export default {
  namespaced: true,
  name: "admins",
  state,
  getters,
  actions,
  mutations,
};
