import axios from 'axios';

// initial state
const state = {
  // record info
  currentRecord: {},
  showRecordInfo: false,

  // paginate records
  filters: { page: 1 },
  totalPages: 0,
  records: [],
}

// mutations
const mutations = {

  // record info
  SET_CURRNET_RECORD(state, record) {
    state.currentRecord = record;
  },

  SHOW_RECORD_INFO(state) {
    state.showRecordInfo = true;
  },

  HIDE_RECORD_INFO(state) {
    state.showRecordInfo = false;
  },

  // paginate records
  SET_FILTERS(state, filters) {
    for (const key in filters) {
      if (filters[key] == '') {
        filters[key] = undefined;
      }
    }
    state.filters = filters;
  },

  SET_TOTAL_PAGES(state, totalPages) {
    state.totalPages = totalPages;
  },

  SET_RECORDS(state, records) {
    state.records = records;
  },

  DELETE_RECORD(state, recordId) {
    state.records.forEach((item, index) => {
      if (item.id == recordId) state.records.splice(index, 1);
    });
  },
}

// actions
const actions = {

  fetchRecords({ state, commit },showAll=1) {
    let url ="";
    showAll==1 ?  url = "/dashboard/messages/list_all?active=1" : url = "/dashboard/messages/list_all"; 
    return axios.get(url, { params: state.filters })
      .then((response) => {
        commit('SET_RECORDS', response.data.data);
        commit('SET_TOTAL_PAGES', response.data.last_page);
      });
  },

  // =========================================================================

  markAsSeen({commit},  record ) {
    var bodyFormData = new FormData();
    
    // console.log(record);
    bodyFormData.append("message_id", record);
    // // console.log(bodyFormData);
    return axios.post(`/dashboard/messages/makeseen`, bodyFormData);
  }
}

export default {
  namespaced: true,
  name: 'contactUs',
  state,
  actions,
  mutations
}
